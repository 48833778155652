import { Controller } from '@hotwired/stimulus'

/**
 * タブを制御する Controller
 */
export class TabController extends Controller {
  /**
   * タブをクリックした時のイベントを設定する
   */
  connect () {
    const tabItems = this.tabItemsTarget.querySelectorAll('.c-link-button-back')
    const container = this.containerTarget.querySelectorAll('.page-recruit__table')
    console.log(tabItems)
    console.log(container)
    this.tabClickReady(tabItems, container)
  }

  /**
   * メニューを選択した時にclassを付け替えるためのイベントを準備
   *
   * @param {Array} tabItems
   * @param {Array} container
   */
  tabClickReady (tabItems, container) {
    tabItems.forEach((element, index) => {
      element.addEventListener('click', () => {
        this.removeActiveClass(tabItems, container)
        this.tabSwitch(index, tabItems, container)
      })
    })
  }

  /**
   * アクティブな状態のclassを削除する
   *
   * @param {Array} tabItems
   * @param {Array} container
   */
  removeActiveClass (tabItems, container) {
    tabItems.forEach((element) => {
      element.classList.remove('c-link-button-back--active')
    })

    container.forEach((element) => {
      element.classList.remove('page-recruit__table--active')
    })
  }

  /**
   * コンテンツの表示を切り替える
   *
   * @param {number} index
   * @param {Array} tabItems
   * @param {Array} container
   */
  tabSwitch (index, tabItems, container) {
    tabItems[index].classList.add('c-link-button-back--active')
    container[index].classList.add('page-recruit__table--active')
  }
}

TabController.targets = ['tabItems', 'container']
