import { Application } from '@hotwired/stimulus'
import { GlobalController } from './controllers/global_controller'
import { HeaderController } from './controllers/header_controller'
import { TabController } from './controllers/tab_controller'
import './scroll-control.js'
import './works-swiper.js'
import './recruit-swiper.js'

const app = Application.start()
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  app.debug = true
}

app.register('global', GlobalController)
app.register('header', HeaderController)
app.register('tab', TabController)
