window.addEventListener('DOMContentLoaded', (event) => {
  const swiper = new Swiper('.page-index-works__swiper', { // eslint-disable-line
    autoplay: true,
    loop: true,
    slidesPerView: 1.2,
    spaceBetween: 28,
    speed: 1000,
    allowTouchMove: true,
    loopAdditionalSlides: 1,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    breakpoints: {
      768: {
        slidesPerView: 3.2,
        spaceBetween: 32
      }
    }
  })
})
