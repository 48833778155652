window.addEventListener('DOMContentLoaded', (event) => {
  const swiper = new Swiper('.page-recruit__swiper', { // eslint-disable-line
    loop: true,
    loopedSlides: 1.5,
    slidesPerView: 'auto',
    spaceBetween: 8,
    speed: 5000,
    allowTouchMove: false,
    autoplay: {
      delay: 0
    }
  })
})
